import { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { SVG_edit } from '../elements/SVG';
import { getText } from '../../helpers/language';
import { MainContext } from '../../contexts/main';
import { InputText } from '../inputs/InputText';
import { InputSelect } from '../inputs/InputSelect';
import { BlockLoading } from './BlockLoading';
import { BlockBillingAddress } from './BlockBillingAddress';

export const BlockSummaryBilling = () => {

    const { mainReducer } = useContext( MainContext );

    const [ stateExpanded, setStateExpanded ] = useState(false);
    const [ stateLoading, setStateLoading ] = useState(true);

    const focus = useRef(false);

    const inputName = useRef(null);
    const inputVatNumber = useRef(null);
    const inputAddress = useRef(null);
    const inputTown = useRef(null);
    const inputProvince = useRef(null);
    const inputZipCode = useRef(null);

    useEffect( () => {
        if ((mainReducer.state.selectedLicense.address.trim() == '') || (mainReducer.state.selectedLicense.name.trim() == '') || (mainReducer.state.selectedLicense.province.trim() == '') || (mainReducer.state.selectedLicense.town.trim() == '') || (mainReducer.state.selectedLicense.zip_code.trim() == '') || (mainReducer.state.selectedLicense.vat_number.trim() == '')) {
            setStateExpanded(true);
        }       
    }, []);

    useLayoutEffect( () => {
        if (focus.current && mainReducer.state.selectedLicense.wantsAnInvoice && stateExpanded && (!stateLoading)) {
            focus.current = false;
            if (inputName.current.value == '') {
                inputName.current.focus();
            } else if (inputVatNumber.current.value == '') {
                inputVatNumber.current.focus();
            } else if (inputAddress.current.value == '') {
                inputAddress.current.focus();
            } else if (inputTown.current.value == '') {
                inputTown.current.focus();
            } else if (inputProvince.current.value == '') {
                inputProvince.current.focus();
            } else if (inputZipCode.current.value == '') {
                inputZipCode.current.focus();
            }
            
        }
    }, [ focus.current, mainReducer.state.selectedLicense.wantsAnInvoice, stateExpanded, stateLoading ]);

    useEffect( () => {
        if (mainReducer.state.countries !== false) {
            setStateLoading(false);
        }
    }, [ mainReducer.state.countries ]);

    const handleEdit = () => {
        setStateExpanded(!stateExpanded);
    }

    const handleWantsAnInvoice = ({ target }) => {
        if (target.checked) {
            focus.current = true;
        }
        mainReducer.dispatch({
            type: 'changeWantsAnInvoice',
            payload: target.checked
        });
    }

    const handleSave = ( value, field ) => {
        const data = {...mainReducer.state.selectedLicense};
        delete data.id;
        delete data.currency;
        delete data.licenses;
        delete data.payer_email;
        delete data.periodicity;
        delete data.wantsAnInvoice;
        data[field] = value;
        const action = {
            type: 'changeBillingData',
            payload: data
        }
        mainReducer.dispatch(action);
    }

    return  <>
            <fieldset className="e__plns__fact">
                <legend>
                    { getText('facturacion') }
                    <span className="e__btn__info">
                        <button className="e__btn" aria-label={ getText('mas_informacion') } title={ getText('mas_informacion') }>i</button>
                        <span className="e__btn__info__wp">
                        { getText('facturacion_txt') }
                        { (!mainReducer.state.EU) &&
                            <>
                            <br /><br />
                            { getText('no_ue_txt') }
                            </>
                        }
                        </span>
                    </span>
                </legend>
                <div className="e__plns__fact__wpr">
                    <div className="e__plns__fact__hdg">
                        <div className="e__plns__fact__hdg__hd">
                            <div className="e__chck">
                                <label htmlFor="cFact">{ getText('datos_de_facturacion') }</label>
                                <span className="btn__toggle visuallyhidden">
                                <input aria-label={ getText("AÑADIR_INFO") } type="checkbox" id="cFact" onChange={ handleWantsAnInvoice } checked={ mainReducer.state.selectedLicense.wantsAnInvoice } />
                                    <span className="slider round"></span>
                                </span>
                            </div>                                   
                        </div>
                        <div className="e__txt">
                            { (!mainReducer.state.EU) &&
                                <span className="e__txt__info">{ getText('no_ue_txt') }</span>
                            }
                        </div>
                    </div>
                    { ( mainReducer.state.selectedLicense.wantsAnInvoice ) && <>
                        { (stateExpanded) ?
                            ( (stateLoading) ?
                                <BlockLoading />
                            :
                            <div className="e__form">
                                <InputSelect field="country" section="billing" list={mainReducer.state.countries} value={ mainReducer.state.selectedLicense.country } handleSave={ handleSave } />
                                <InputText ref={ inputName } field="name" section="billing" value={ mainReducer.state.selectedLicense.name } handleSave={ handleSave } />
                                <InputText ref={ inputVatNumber } field="vat_number" section="billing" value={ mainReducer.state.selectedLicense.vat_number } handleSave={ handleSave } />
                                <InputText ref={ inputAddress } field="address" section="billing" value={ mainReducer.state.selectedLicense.address } handleSave={ handleSave } />
                                <InputText ref={ inputTown } field="town" section="billing" value={ mainReducer.state.selectedLicense.town } handleSave={ handleSave } />
                                {mainReducer.state.provinces[mainReducer.state.selectedLicense.country] === undefined ?
                                    <InputText ref={ inputProvince } field="province" section="billing" value={ mainReducer.state.selectedLicense.province } handleSave={ handleSave } />
                                :
                                    <InputSelect ref={ inputProvince } field="province" list={mainReducer.state.provinces[mainReducer.state.selectedLicense.country]} canBeEmpty={ true } section="billing" value={ mainReducer.state.selectedLicense.province } handleSave={ handleSave } />
                                }
                                <InputText ref={ inputZipCode } field="zip_code" section="billing" value={ mainReducer.state.selectedLicense.zip_code } handleSave={ handleSave } />
                                </div>
                            )
                        :
                            <fieldset className="e__addrs">
                                <BlockBillingAddress />
                                
                                <button className="e__btn e__btn__trtry e__btn__trtry--mini" title={ getText('cambiar') } onClick={ handleEdit } disabled={!mainReducer.state.editableBudget}>
                                    <span className='e__btn__ico'><SVG_edit /></span>
                                </button>
                            </fieldset>
                        }
                    </>}
                </div>
            </fieldset>
            </>;
}