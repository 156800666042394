import urlResources from '../resources/urls.json';

export const getURL = ( environment, resource ) => {
    let url = 'https://' + environment.subdomain + '.' + environment.entorno + '/';
    if (resource != undefined) {
        try {
            url += urlResources[resource][environment.lang];
        } catch (error) {
            url += resource;
        }
    }
    return url;
}

export const navigateTo = ( resource, navigate, environment, appReducer, replace=false ) => {
    appReducer.dispatch({
        type: 'clearNotifications'
    });
    appReducer.dispatch({
        type: 'clearFieldErrors'
    });
    navigate({
        pathname: '/' + urlResources[resource][environment.lang],
        search: location.search,
    },{
        replace: replace
    });
}

export const redirectToLogin = ( environment, selectedLicense ) => {
    let url = getURL( environment, 'login' ) + '?redirect=premiumCheckout';
    let qs = '?id=' + selectedLicense.id;
    qs += '&periodicity=' + selectedLicense.periodicity;
    if ( environment.showConsent == 'no' ) {
        qs += '&showConsent=no';
        url += '&showConsent=no';
    }
    url += '&extra=' + encodeURIComponent(qs);
    location.replace(url);
}

export const redirectToLoginBudget = ( environment, selectedLicense ) => {
    let url = getURL( environment, 'login' ) + '?redirect=premiumBudget';
    let qs = '?id=' + selectedLicense.id;
    qs += '&periodicity=' + selectedLicense.periodicity;
    if ( environment.showConsent == 'no' ) {
        qs += '&showConsent=no';
        url += '&showConsent=no';
    }
    url += '&extra=' + encodeURIComponent(qs);
    location.replace(url);
}

export const redirectTo = ( environment, resource ) => {
    let url = getURL( environment, resource );
    if ( environment.showConsent == 'no' ) {
        url += '?showConsent=no';
    }
    location.replace(url);
}