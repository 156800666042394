import { useContext } from 'react';
import { MainContext } from '../../contexts/main';
import OutsideAlerter from '../elements/OutsideAlerter';
import { SVG_close } from '../elements/SVG';
import { getURL } from '../../helpers/urls';

export const ModalLicense = () => {

    const { appReducer, environment } = useContext( MainContext );

    const handleClose = () => {
        const action = {
            type: 'closeModal',
            payload: 'license'
        }
        appReducer.dispatch(action);
    }

    return  <>
            <div className="e__modal">
                <OutsideAlerter action={ handleClose }>
                <div className="e__modal__lcns">
                    <button className="e__btn e__btn__close" onClick={ handleClose }><SVG_close /></button>
                    <iframe src={ getURL(environment,'licencia') + '?basic=true' + ((environment.showConsent == 'no') ? '&showConsent=no' : '') } />
                </div>
                </OutsideAlerter>
                <div className="e__modal__bckg"></div>
            </div>
            </>;

}