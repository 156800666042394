import { useRef, useContext, useState, useEffect } from 'react';
import { MainContext } from '../../contexts/main';
import { markBudgetAsSeen } from '../../helpers/api';
import { getText } from '../../helpers/language';
import { getURL } from '../../helpers/urls';
import { SVG_copy, SVG_link, SVG_payforme } from '../elements/SVG';
import { BlockLoading } from './BlockLoading';
import config from '../../resources/config.json';

export const BlockPayForMe = () => {

    const { environment, mainReducer } = useContext( MainContext );

    const [ stateCopied, setStateCopied ] = useState(false);

    const inputPayForMe = useRef(null);

    useEffect( () => {
        setStateCopied(false);
    }, [ mainReducer.state.budgetGenerated ]);

    const handleOnFocus = () => {
        inputPayForMe.current.select();
        inputPayForMe.current.setSelectionRange(0,99999);
    }

    const handleCopyLink = () => {
        handleOnFocus();
        navigator.clipboard.writeText(inputPayForMe.current.value);
        handleOnCopy();
    }

    const handleOnCopy = () => {
        if (!stateCopied) {
            setStateCopied(true);
            markBudgetAsSeen({
                environment: environment.entorno,
                budget: mainReducer.state.budgetGenerated
            });
        }
    }

    return  <>
            <fieldset className={`e__plns__link e__plns__link--${mainReducer.state.selectedLicense.id}${(config.plans[mainReducer.state.selectedLicense.id].highlight) ? ' e__plns__link--highlight' : ''}`}>
                <legend>
                    <span className="e__plns__link__ico">
                        <SVG_payforme />
                    </span>
                    { getText('pagar_por_mi') }
                    <span className="e__btn__info">
                        <button className="e__btn" aria-label={ getText('mas_informacion') } title={ getText('mas_informacion') }>i</button>
                        <span className="e__btn__info__wp">
                            { getText('pagar_por_mi_txt') }
                        </span>
                    </span>
                </legend>
                {(mainReducer.state.budgetGenerated === false) ?
                    <BlockLoading />
                :
                    <div className="e__plns__link__wpr">
                        <div className="e__textformular e__textformular--ico success">
                            <label>{ getText('pagar_por_mi_txt2') }</label>
                            <div className="e__textformular__wrapper">
                                <span className="e__textformular__input">
                                    <input placeholder={getText(' ')} onCopy={ handleOnCopy } onFocus={ handleOnFocus } ref={ inputPayForMe } dir="ltr" readOnly size="45" title={ getText('pagar_por_mi_txt2') } value={ getURL(environment,'shortcutBudget') + mainReducer.state.budgetGenerated + '/' + ((environment.showConsent == 'no') ? '?showConsent=no' : '') } />
                                </span>
                                <span className="e__textformular__icons e__ico">
                                    <SVG_link />
                                </span>
                                <button className="e-btn e-btn--mini-square"  onClick={ handleCopyLink }>
                                    <span>
                                        <SVG_copy />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </fieldset>
            </>;
}