import { useContext, useState } from 'react';
import { MainContext } from '../../contexts/main';
import { getText } from '../../helpers/language';
import { BlockPlan } from './BlockPlan';
import {  scrollTo, tsToDate } from '../../helpers/functions';
import config from '../../resources/config.json';
import { BlockInfoLicense } from './BlockInfoLicense';
import { SVG_back } from '../elements/SVG';
import { BlockTeamPlan } from './BlockTeamPlan';
import { getURL } from '../../helpers/urls';

const TYPE_PLANS = {
    INDIVIDUAL: 'individual',
    TEAM: 'team'
}

export const BlockPlans = () => {

    const { environment, mainReducer, appReducer } = useContext(MainContext);
    const currentNumLicenses = mainReducer.state.currentLicense.licenses;
    const [typePlanSelected, setTypePlanSelected] = useState(currentNumLicenses > 1 ? TYPE_PLANS.TEAM : TYPE_PLANS.INDIVIDUAL);
    const handleOpenLicense = () => {
        appReducer.dispatch({
            type: 'openModal',
            payload: {
                id: 'license'
            }
        });
    }

    const onClickTeamPlanHandler = () => {
        const url = getURL(environment, 'teamPlan');
        window.open(url, '_blank').focus();
    }

    return <>
        <section className="e__plns__lst__main">
            <div className="e__plns__lst__hd">
                {(mainReducer.state.user !== false) &&
                    <>
                        <div className="e__plns__hdg__wrp">
                            <div className='e__plns__hdg__wrp__lst'>
                                {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense <= mainReducer.state.currentLicense.id)) &&
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{getText('plan_asignado')}:</div>
                                        <BlockInfoLicense license={{ id: mainReducer.state.subLicense }} />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                }
                                {((mainReducer.state.inProgressLicense.id == mainReducer.state.currentLicense.id) && (mainReducer.state.inProgressLicense.periodicity == mainReducer.state.currentLicense.periodicity) && (mainReducer.state.inProgressLicense.licenses == mainReducer.state.currentLicense.licenses)) ?
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{getText('plan_contratado')}:</div>
                                        <BlockInfoLicense license={mainReducer.state.currentLicense} />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                    :
                                    <>
                                        <div className='e__plns__hdg__wrp__lst__plan'>
                                            <div className="e__plns__lst__hd__hdg">{getText('plan_contratado')}:</div>
                                            <BlockInfoLicense license={mainReducer.state.inProgressLicense} />
                                            <span className="e__plns__lst__hd__ico">
                                                <SVG_back />
                                            </span>
                                        </div>
                                        <div className='e__plns__hdg__wrp__lst__plan'>
                                            <div className="e__plns__lst__hd__hdg">{getText('a_partir_de')} {tsToDate(mainReducer.state.inProgressLicense.expiration, environment.lang)}:</div>
                                            <BlockInfoLicense license={mainReducer.state.currentLicense} />
                                            <span className="e__plns__lst__hd__ico">
                                                <SVG_back />
                                            </span>
                                        </div>
                                    </>
                                }
                                {((mainReducer.state.subLicense !== false) && (mainReducer.state.subLicense > mainReducer.state.currentLicense.id)) &&
                                    <div className='e__plns__hdg__wrp__lst__plan'>
                                        <div className="e__plns__lst__hd__hdg">{getText('plan_asignado')}:</div>
                                        <BlockInfoLicense license={{ id: mainReducer.state.subLicense }} />
                                        <span className="e__plns__lst__hd__ico">
                                            <SVG_back />
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="e__switch">
                <div className="e__switch__wrapper">
                    <div className="e__switch__header">&nbsp;</div>
                    <button className={`e__switch__btn ${typePlanSelected === TYPE_PLANS.INDIVIDUAL ? 'active' : ''}`} onClick={() => { setTypePlanSelected(TYPE_PLANS.INDIVIDUAL) }}>{getText('individual_plan')}</button>
                </div>

                <div className="e__switch__wrapper">
                    <div className="e__switch__header">{getText('new_msg')}</div>
                    <button className={`e__switch__btn ${typePlanSelected === TYPE_PLANS.TEAM ? 'active' : ''}`} onClick={() => { setTypePlanSelected(TYPE_PLANS.TEAM) }}>{getText('team_plans')}</button>
                    <div className="e__switch__body">{getText('free_msg').replace('{type_coin}', mainReducer.state.currency.code)}</div>
                </div>
            </div>
            <div className={`e__plns__lst__wpr${(mainReducer.state.user === false) ? ' e__plns__lst__wpr--basic' : ''}`}>
                {typePlanSelected === TYPE_PLANS.TEAM ?
                    <BlockTeamPlan onClick={onClickTeamPlanHandler}/> :
                    Object.keys(config.plans).map(index =>
                        <BlockPlan key={index} id={index} teamPlanClick={onClickTeamPlanHandler} />
                    )}

            </div>
            <div className="e__txt__lgl">{getText('licencias_y_condiciones_disclaimer')} <button onClick={handleOpenLicense} className="e__lnk">{getText('licencias_y_condiciones')}</button></div>
            <div className="e__plns__lst__main__nv">
                <button onClick={() => scrollTo('sectionPriceTable')}>{getText('comparar_title')}</button>
                <button onClick={() => scrollTo('sectionFAQ')} >{getText('faq')}</button>
            </div>
        </section>
    </>;
}